import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/AboutUs")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/Search",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Search/Index")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Marketplace/Index")),
  },
  {
    exact: true,
    path: "/activity",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Activity/index")),
  },
  {
    exact: true,
    path: "/nft-details",
    layout: HomeLayout,
    guard: false,
    component: lazy(() => import("src/views/pages/NftDetails/index")),
  },
  {
    exact: true,
    path: "/nft-asset-details",
    layout: HomeLayout,
    guard: false,
    component: lazy(() => import("src/views/pages/AssetDetails/index")),
  },
  {
    exact: true,
    path: "/jurney",
    layout: HomeLayout,
    guard: false,
    component: lazy(() => import("src/views/pages/NftDetails/Roadmap")),
  },
  {
    exact: true,
    path: "/collection",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftDetails/Collection")),
  },
  {
    exact: true,
    path: "/my-design",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyDesigns/Index")),
  },
  {
    exact: true,
    path: "/create",
    layout: HomeLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Create-NFT/CreateNFT")),
  },
  {
    exact: true,
    path: "/resell-nft",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create-NFT/ResellNFT")),
  },
  {
    exact: true,
    path: "/chat",
    layout: HomeLayout,
    component: lazy(() => import("src/chat/index")),
  },
  {
    exact: true,
    path: "/user",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/UsersView/UsersView")),
  },
  {
    exact: true,
    path: "/profile",
    gard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/index")),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FooterPages/FAQ")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FooterPages/AboutUs")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/FooterPages/TermsAndConditions")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FooterPages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FooterPages/ContactUs")),
  },
  {
    exact: true,
    path: "/notification",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Notification")),
  },
  {
    layout: HomeLayout,
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  ,
];
