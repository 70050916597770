export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 5;
export const tokenContract = "0x99f369ebc720d07797d632fd582f587b8618b4f3";
export const MarketplaceContractKoven =
  "0x1dced91c6af5a353233df52a12ba45780673619d";
export const NftTokenAddress = "0x3f63aea454a9159cf82aa09a50de07f77f8adc26";

export const getMarketplaceContractAddress = (chianId) => {
  switch (chianId.toString()) {
    case "5":
      return MarketplaceContractKoven;
  }
};

export const getNetworkDetails = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return [
        {
          chainId: "0x2A",
          chainName: "Kovan Test Network",
          nativeCurrency: {
            name: "Kovan Test Network",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: [
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          ],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
      case "5":
      return [
        {
          chainId: "5",
          chainName: "Goerli Test Network",
          nativeCurrency: {
            name: "Goerli Test Network",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: [
            "https://goerli.infura.io/v3/",
          ],
          blockExplorerUrls: ["https://goerli.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com/"],
        },
      ];
    case "80001":
      return [
        {
          chainId: "0x13881",
          chainName: "Mumbai testnet",
          nativeCurrency: {
            name: "Mumbai testnet",
            symbol: "MATIC",
            decimals: 18,
          },
          rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
          blockExplorerUrls: ["https://explorer-mumbai.maticvigil.com/"],
        },
      ];
    case "43113":
      return [
        {
          chainId: "0xA869",
          chainName: "Avalanche Network",
          nativeCurrency: {
            name: "Avalanche Network",
            symbol: "AVAX",
            decimals: 18,
          },
          rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
          blockExplorerUrls: ["https://testnet.snowtrace.io/"],
        },
      ];
    case "4002":
      return [
        {
          chainId: "0xfa2",
          chainName: "Fantom Opera",
          nativeCurrency: {
            name: "Fantom Opera",
            symbol: "FTM",
            decimals: 18,
          },
          rpcUrls: ["https://rpc.testnet.fantom.network/"],
          blockExplorerUrls: ["https://rpc.testnet.fantom.network/"],
        },
      ];
  }
};

export const networkList = [
  {
    name: "Ethereum",
    chainId: "5",
    description: "",
    symbol: "ETH",
  },
  
];
