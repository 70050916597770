import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import { AiFillTwitterCircle } from "react-icons/ai";
import { ImFacebook2 } from "react-icons/im";
import { FiInstagram } from "react-icons/fi";
import { BsTelegram } from "react-icons/bs";
import CopyrightIcon from "@material-ui/icons/Copyright";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "transparent",
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: "10px",
    zIndex: 2,
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "20px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  links: {
    color: "#fff",
    textDecoration: "none",
    marginLeft: "10px",
    textAlign: "left",
    "&:hover": {
      color: "rgba(83, 68, 255, 0.9)",
    },
  },
  middleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    flexWrap: "wrap",
    [theme.breakpoints.only("sm")]: {
      justifyContent: "left",
    },
    [theme.breakpoints.only("xs")]: {
      margin: "20px 0px",
    },
  },
  mainGrid: {
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
      display: "flex",
    },
  },
  iconsBox: {
    display: "flex",
    justifyContent: "space-around",
    color: "white",
    marginTop: "10px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  iconsname1: {
    borderRadius: "3px",
    color: "#0088cc",
    background: "white",
    borderRadius: "100%",
    border: "1px solid white",
    "&:hover": {
      // color: "#0d6efd",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const [staticData, setStaticData] = useState([]);

  const _staticContent = async () => {
    try {
      const res = await axios.get(apiConfig?.staticContentList);
      if (res?.status === 200) {
        setStaticData(res?.data?.result);
      }
    } catch (error) {
      //  setIsLoader(false);
      //  console.log("error", error);
    }
  };

  useEffect(() => {
    _staticContent();
  }, []);

  return (
    <>
      <Box className={classes.footerSection}>
        <hr />
        <Box style={{ margin: "20px 10px 0" }}>
          <Container alignItems="center" maxWidth="lg">
            <Box alignItems="center">
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={2}
                  lg={2}
                  className={classes.mainGrid}
                >
                  <img
                    src="images/HubaLogo1.png"
                    style={{ maxWidth: "160px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={8}
                  lg={8}
                  className={classes.middleSection}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className={classes.mainGrid}
                  >
                    <NavLink
                      activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
                      className={classes.links}
                      to="/about-us"
                    >
                      About Us
                    </NavLink>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    className={classes.mainGrid}
                  >
                    <NavLink
                      activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
                      className={classes.links}
                      to="/terms-conditions"
                    >
                      Terms & Conditions
                    </NavLink>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={2}
                    className={classes.mainGrid}
                  >
                    <NavLink
                      activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
                      className={classes.links}
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </NavLink>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className={classes.mainGrid}
                  >
                    <NavLink
                      activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
                      className={classes.links}
                      to="/contact-us"
                    >
                      Contact Us
                    </NavLink>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={2}
                    className={classes.mainGrid}
                  >
                    {" "}
                    <NavLink
                      activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
                      className={classes.links}
                      to="/faq"
                    >
                      FAQ
                    </NavLink>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  lg={2}
                  className={classes.mainGrid}
                >
                  <Box>
                    <Box className={classes.iconsBox}>
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={6}>
                          <a
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            href="http://www.instagram.com"
                          >
                            <FiInstagram
                              style={{
                                color: "crimson",
                                border: "1px solid white",
                                background: "white",
                                borderRadius: "50%",
                                fontSize: "25px",
                              }}
                            />
                          </a>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={6}>
                          {" "}
                          <a
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            href="https://web.telegram.org/k/"
                          >
                            {/* <img src="./images/telegram.png"></img> */}
                            <BsTelegram
                              className={classes.iconsname1}
                              style={{ fontSize: "25px" }}
                            />
                          </a>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={6}>
                          {" "}
                          <a target="_blank" href="http://www.facebook.com">
                            <ImFacebook2
                              style={{
                                color: "#0088cc",
                                background: "white",
                                borderRadius: "50%",
                                border: "1px solid white",
                                fontSize: "25px",
                              }}
                            />
                          </a>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={6}>
                          {" "}
                          <a target="_blank" href="http://www.twitter.com">
                            <AiFillTwitterCircle
                              style={{
                                color: " #229ED9",
                                background: "white",
                                borderRadius: "50%",
                                fontSize: "27px",
                              }}
                            />
                          </a>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box textAlign="center" pt={6}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CopyrightIcon fontSize="small" />
                  Copyright All rights reserved.
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
