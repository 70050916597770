import React, { createContext, useState, useEffect } from "react";
import { injected, SUPPORTED_WALLETS } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import apiConfig from "src/config/ApiConfig";
import axios from "axios";
import { ACTIVE_NETWORK, getNetworkDetails, tronGridURL } from "src/constants";
import { toast } from "react-toastify";
import Web3 from "web3";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function UserAuthContext(props) {
  const { activate, account, chainId, library, deactivate } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMSG, setSuccessMSG] = useState("");
  const [userData, setUserData] = useState();
  const [liveEthPrice, setLiveEthPrice] = useState(1);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  let data = {
    isLogin,
    isLoading,
    errorMsg,
    successMSG,
    userData,
    liveEthPrice,
    serviceFee,
    yourWalletBalance,
    notificationList,
    unreadCount,
    getProfileHandler: (token) => getProfileHandler(token),
    updateUser: (account) => {
      setSession(account);
    },
    logoutHanlder: () => {
      setUserData();
      setIsLogin(false);
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("walletName");
      deactivate();
    },
    connectWallet: (data) => connectToWallet(data),
  };

  //ACCOUNT CHANGE
  window.addEventListener("message", function (e) {
    if (window.tronWeb) {
      if (e.data.message && e.data.message.action == "setAccount") {
      }
    }
  });

  //NETWORK CHANGE

  window.addEventListener("message", function (e) {
    if (e.data.message && e.data.message.action == "setNode") {
      console.log("setNode event", e.data.message);
      if (e.data.message.data.node.chain == "_") {
        console.log("tronLink currently selects the main chain");
      } else {
        console.log("tronLink currently selects the side chain");
      }
    }
  });

  // useEffect(() => {
  //   const web = new WebSocket(socketURL);
  //   // console.log("sdkjgfsdkjfhdsfkjgsf ->>>>",notificationList);
  //   // console.log("sdkjgfsdkjfhdsfkjgsf ->>>>");
  //   const accessToken = sessionStorage.getItem("token");
  //   if (accessToken && account) {
  //     try {
  //       web.onopen = () => {
  //         const dataToSend = {
  //           option: "notification",
  //           token: accessToken,
  //         };
  //         web.send(JSON.stringify(dataToSend));
  //         web.onmessage = async (event) => {
  //           console.log("socket on message ====>", event);
  //           if (event.data !== "[object Promise]" && event.data !== "null") {
  //             let obj = JSON.parse(event.data);
  //             // console.log("onmessage: ", obj.data);
  //             if (obj.data && obj.data.length > 0) {
  //               setNotificationList(obj.data);
  //               setUnreadCount(obj.unReadCount);
  //             } else {
  //               setNotificationList([]);
  //               setUnreadCount(0);
  //             }
  //           }
  //         };
  //       };
  //       return () => {
  //         setNotificationList();
  //         setUnreadCount(0);
  //         web.close();
  //       };
  //     } catch (err) {}
  //   }
  // }, [isLogin]);

  const getProfileHandler = async (token) => {
    try {
      const res = await axios.get(apiConfig.profile, {
        headers: {
          token,
        },
      });
      setIsLoading(false);
      if (res.data.statusCode === 200) {
        setUserData(res.data.result);
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  const getLiveEthPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
      );
      if (res.status === 200) {
        setLiveEthPrice(res.data.ethereum.usd);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress,
      });
      if (res.data.statusCode === 200) {
        // toast.success("Wallet connected successfully");
        getProfileHandler(res.data.result.token);
        setTokenSession(res.data.result.token);
        setSuccessMSG(res.data.responseMessage);
      } else {
        deactivate();
        setIsLogin(false);
        setUserData();
        setIsLoading(false);
      }
    } catch (error) {
      deactivate();
      setIsLogin(false);
      console.log("ERROR", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (account) {
      connectWalletHandler(account);
    } else {
      setIsLogin(false);
      setUserData();
    }
  }, [account]);

  // tronWalletAddress
  // useEffect(() => {
  //   console.log("window.tronWeb.ready", window?.tronWeb?.ready);
  //   console.log("tronWalletAddress", tronWalletAddress);

  //   if (window.tronWeb && window.tronWeb.ready && tronWalletAddress) {
  //     connectWalletHandler(tronWalletAddress);
  //   } else {
  //     setIsLogin(false);
  //     setUserData();
  //   }
  // }, [tronWalletAddress]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const addNetworkHandler = async () => {
    const NetworkDetails = getNetworkDetails(ACTIVE_NETWORK);
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          // swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(4));
  };

  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);

  const connectToWallet = (data) => {
    if (data) {
      const connector = data.data.connector;
      sessionStorage.removeItem("walletName");
      sessionStorage.setItem("walletName", data.name);
      setErrorMsg("");
      setSuccessMSG("");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          console.log("error", error.message);
          setErrorMsg(error.message + " Please install " + data.name);
          activate(connector);
          setIsLoading(false);
        }
      });
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };

  useEffect(() => {
    getLiveEthPrice();
    console.log("sessionStorage.getItem", sessionStorage.getItem("walletName"));
    if (
      sessionStorage.getItem("walletName") &&
      (sessionStorage.getItem("walletName") !== "WALLETCONNECT" ||
        sessionStorage.getItem("walletName") !== "METAMASK")
    ) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === sessionStorage.getItem("walletName")
      );
      connectToWallet(selectectWalletDetails[0]);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const userAddress = sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
