import React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${"/images/background.d884bc33378253f5240d5556d9146e22.png"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  mainBody: {
    position: "relative",
    zIndex: 1,
    minHeight: "65.4vh",
    "& .shade1": {
      top: "38%",
      left: "1%",
      right: "auto",
      width: "721px",
      bottom: " auto",
      filter: "blur(100px)",
      height: "721px",
      opacity: "0.55",
      zIndex: "-1",
      position: "fixed",
      borderRadius: "1000px",
      backgroundImage:
        " radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgba(128, 68, 255, 0.5) 95.78%)",
    },
    "& .shade2": {
      top: "60%",
      left: "60%",
      right: "auto",
      width: "1045px",
      bottom: " auto",
      filter: "blur(100px)",
      height: "1045px",
      opacity: "0.55",
      zIndex: "-1",
      position: "fixed",
      borderRadius: "1000px",
      backgroundImage:
        " radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgba(195, 68, 255, 0.5) 95.78%)",
    },
    "& .shade3": {
      top: "38%",
      left: "10%",
      right: "auto",
      width: "1307px",
      bottom: " auto",
      height: "1307px",
      opacity: "0.55",
      zIndex: "-1",
      position: "fixed",
      borderRadius: "1000px",
      background: "rgba(62, 31, 89, 0.8)",
      filter: "blur(450px)",
    },
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      />

      <div className={classes.mainBody}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
