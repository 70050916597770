import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Grid,
  Avatar,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation, NavLink } from "react-router-dom";
import Logo from "./../../component/Logo";
import { IoIosNotifications } from "react-icons/io";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { copyTextById, sortAddress } from "src/utils";
import apiConfig, {
  privateBlockchainUrl,
  socketURL,
  socketConfig,
} from "src/config/ApiConfig";
import axios from "axios";
// import io from "socket.IO-client";
import * as io from "socket.io-client";
// import apiConfig, {  } from "src/config/ApiConfig";

const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Explore",
    href: "/marketplace",
  },
  {
    label: "Activity",
    href: "/activity",
  },
  {
    label: "My Designs",
    href: "/my-design",
  },
];
let socket;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    margin: "0 7px",
    textDecoration: "none",
    // "&:active": {
    //   background: "red",
    // },

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "rgba(83, 68, 255, 0.9)",
      borderBottom: "2px solid #fff",
    },
    "&:hover": {
      color: "rgba(83, 68, 255, 0.9)",
    },
    "&:last-child": {
      backgroundColor: "#1ed760",
    },
  },
  menuButton1: {
    width: "100%",
    justifyContent: "left",
  },
  login: {
    // backgroundColor: "rgba(83, 68, 255, 0.9)",
    marginLeft: "10px",
  },
  loginButton: {
    height: "28px",
    border: "2px solid white",
    marginTop: "-5px",
    width: "28px",
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    // borderBottom:"1px solid #3c3c3c",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    width: "100%",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "40px",
    // marginTop: "3px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "10px",
    // width: "470px",
    // backgroundColor: "#E6E6E6",
    border: "2px solid rgb(229, 232, 235)",
    "&:hover": {
      // backgroundColor: "#ececec",
      // border: "1px solid #300760",
    },

    marginLeft: 20,
    // width: "366px",
    // maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      // width: "250px",
    },
  },
  search1: {
    height: "40px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "10px",
    width: "340px",
    paddingTop: "3px",
    // backgroundColor: "#E6E6E6",
    border: "2px solid rgb(229, 232, 235)",
    "&:hover": {
      // backgroundColor: "#ececec",
      // border: "1px solid #300760",
      "@media(max-width:1280px)": {
        width: "100px",
      },
    },

    marginLeft: 20,
    // width: "366px",
    // maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      // width: "250px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#fff",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid rgba(83, 68, 255, 0.9)",
    padding: "0 15px",
    background: "rgba(83, 68, 255, 0.9)",
    borderRadius: "50px",
    height: "31px",
    margin: "0 7px",
    "&:hover": {
      background: "#fff",
      color: "rgba(83, 68, 255, 0.9)",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputBox: {
    position: "relative",
    color: "#fff",
  },
  inputInput: {
    padding: "5px 17px 7px 0px",
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    // minWidth: "90%",
    width: "415px !important",
    [theme.breakpoints.up("sm")]: {
      width: "200px",
      // "&:focus": {
      //   width: "100%",
      // },
    },
  },
  inputInput1: {
    marginTop: "2px",
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    // minWidth: "90%",
    width: "290px !important",
    [theme.breakpoints.up("sm")]: {
      width: "200px",
      // "&:focus": {
      //   width: "100%",
      // },
    },
    "@media(max-width:230px)": {
      width: "120px !important",
    },
  },
  inputInputMobile: {
    padding: "11px 8px 8px 0px",
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "421px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "195px",
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    // top: "25px !important",
  },
  homeBtn: {
    marginLeft: "10px",
    "&:hover": {
      color: "rgba(83, 68, 255, 0.9)",
    },
  },
  NavBox: {
    color: "white",

    textDecoration: "none",
    "&:hover": {
      color: "rgba(83, 68, 255, 0.9)",
    },
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useContext(UserContext);
  console.log("****user***", user);
  const { chainId, account } = useWeb3React();
  const [searchKey, setSearchKey] = useState("");
  const classes = useStyles();
  const [_notification_event, setNotificationEvent] = useState();
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);

  const {
    loginButton,
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    login,
    logoDrawer,
    containerHeight,
    inputInputMobile,
    search,
    searchIcon,
    mainHeader,
    inputInput,
    inputRoot,
    wallet,
    submenu,
    inputInput1,
    homeBtn,
    NavBox,
  } = useStyles();
  const history = useHistory();
  console.log("user--sss>>", user);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  // connect to socket and online user
  // const socketInitializer = async (id) => {
  //   socket = io("http://172.16.1.246:3032");

  //   socket.on("connect", () => {
  //     socket.emit("onlineUser", {
  //       user_id: user?.userData?.id,
  //     });
  //     console.log("connected-->>>>socket");
  //   });
  // };
  // emit notification function
  // const _ntificationEvent = () => {
  //   console.log("socket-->>", socket);
  //   socket.emit("getNotificationList", {
  //     token: window.sessionStorage.getItem("token"),
  //   });
  // };

  //on notification function
  // const _notification_Listener_Function = () => {
  //   socket.on(socketConfig._notificationList, (data) => {
  //     console.log("data--->>data", data);
  //     setNotificationEvent(data?.result);
  //   });
  // };
  // useEffect(() => {
  //   if (user?.isLogin && user?.userData?.id) {
  //     console.log("---calling function------");
  //     socketInitializer(user?.userData?.id);
  //   }
  // }, [user]);
  // useEffect(() => {
  //   _ntificationEvent();
  // }, [socket]);
  console.log("user-->>", user);
  const { mobileView, drawerOpen } = state;
  console.log("search->", searchKey);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1290
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const handleToggle = (name) => {
    setOpen1({ ...open1, [name]: !open1[name] });
  };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }
  const [_isCall, setCall] = useState(false);
  const handleSearch = async (event) => {
    event.preventDefault();
    console.log(searchKey);
    let searchKy = searchKey;
    if (searchKey != "") {
      if (searchKey.includes(":")) {
        let inputKeysValue = searchKey.split(":");
        let obj;
        if (inputKeysValue.length > 2) {
          obj = {
            keyInput: inputKeysValue[0].trim()
              ? inputKeysValue[0].trim()
              : undefined,
            valueInput: inputKeysValue[1].trim()
              ? inputKeysValue[1].trim()
              : undefined,
            unitInput: inputKeysValue[2].trim()
              ? inputKeysValue[2].trim()
              : undefined,
          };
        } else {
          obj = {
            keyInput: inputKeysValue[0].trim()
              ? inputKeysValue[0].trim()
              : undefined,
            valueInput: inputKeysValue[1].trim()
              ? inputKeysValue[1].trim()
              : undefined,
            unitInput: "",
          };
        }
        searchKy = JSON.stringify(obj);
      }
      await axios
        .get(apiConfig.explorerList, {
          params: {
            search: searchKy,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            console.log("----====");
            setCall(true);
            if (res?.data?.result.length != 0) {
              history.push("/Search", {
                searchKey: searchKey,
                data: res?.data?.result?.rows,
                _isCall,
              });
            }
          } else {
            console.log("fdsffgsfjh");
          }
        })
        .catch((err) => {
          history.push("/Search", { searchKey: searchKey, data: [], _isCall });
          console.log("34535");
        });
    }
  };

  const [_profile, setProfile] = useState("");
  const ProfileSection = async () => {
    try {
      await axios({
        url: apiConfig.profile,
        method: "GET",
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      })
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            setProfile(res?.data?.result);
            console.log("res?.data?.result", setProfile);
          }
        })
        .catch((err) => {
          console.log("********");
        });
    } catch {
      console.log("********");
    }
  };
  useEffect(() => {
    ProfileSection();
  }, []);
  const displayDesktop = () => {
    // const classes = useStyles();

    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {productLogo}
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {user?.isLogin === false ? (
              <>
                <Grid item lg={6} md={12} xs={12} sm={12}>
                  {" "}
                  <div className={classes.search}>
                    <form onSubmit={handleSearch}>
                      <Button className={searchIcon}>
                        <SearchIcon />
                      </Button>
                      <InputBase
                        style={{ marginTop: "5px" }}
                        placeholder="Search By Name, Category, Organization, NFTID and Attributes"
                        classes={{
                          root: inputRoot,
                          input: inputInput,
                        }}
                        type="search"
                        inputProps={{ "aria-label": "search" }}
                        value={searchKey}
                        onChange={(e) => {
                          setSearchKey(e.target.value);
                        }}
                      />
                    </form>
                  </div>
                </Grid>
              </>
            ) : (
              <>
                {" "}
                <Grid
                  item
                  lg={3}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ width: "50%" }}
                >
                  {" "}
                  <div className={classes.search1}>
                    <form onSubmit={handleSearch}>
                      <Button className={searchIcon}>
                        <SearchIcon />
                      </Button>
                      <InputBase
                        placeholder="Search By Name, Category, Organization"
                        classes={{
                          root: inputRoot,
                          input: inputInput1,
                        }}
                        type="search"
                        inputProps={{ "aria-label": "search" }}
                        value={searchKey}
                        onChange={(e) => {
                          setSearchKey(e.target.value);
                        }}
                      />
                    </form>
                  </div>
                </Grid>
              </>
            )}

            {user?.isLogin === false ? (
              <>
                {" "}
                <Grid
                  item
                  lg={6}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                  {getMenuButtons()}
                  {user.isLogin && (
                    <Button
                      aria-label="delete"
                      size="small"
                      component={Link}
                      to="/notification"
                      // onClick={_ntificationEvent}
                    >
                      <IoIosNotifications
                        fontSize="30"
                        style={{ color: "rgba(96, 104, 125, 1)" }}
                      />
                      {user?.unreadCount !== 0 ? <span className="dots" /> : ""}
                    </Button>
                  )}
                  <input
                    type="text"
                    id="headerWalletAddress"
                    value={user.userData?.wallet_address}
                    style={{ display: "none" }}
                  />
                  <Button
                    className={wallet}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(privateBlockchainUrl, "_blank");
                    }}
                  >
                    Switch to Private
                  </Button>
                  {user.isLogin && account ? (
                    <CopyToClipboard text={user.userData?.wallet_address}>
                      <Button
                        className={wallet}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={() => toast.info("Copied")}
                      >
                        {sortAddress(account)}{" "}
                      </Button>
                    </CopyToClipboard>
                  ) : (
                    <Button
                      className={wallet}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      to="/wallet"
                      component={Link}
                    >
                      Connect wallet
                    </Button>
                  )}
                  {user.isLogin && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      className={login}
                      ref={anchorRef.user}
                      aria-controls={open1.user ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={() => handleToggle("user")}
                    >
                      {" "}
                      <Avatar
                        className={loginButton}
                        src={_profile?.profile_picture}
                      />
                    </IconButton>
                  )}
                  <Popper
                    open={open1.user}
                    anchorEl={anchorRef.user.current}
                    role={undefined}
                    transition
                    disablePortal
                    className={submenu}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={(e) => handleClose2(e, "user")}
                          >
                            <MenuList
                              autoFocusItem={open1.user}
                              id="menu-list-grow"
                              onKeyDown={(e) => handleListKeyDown(e, "user")}
                            >
                              <MenuItem component={Link} to="/profile">
                                My Profile
                              </MenuItem>
                              <MenuItem component={Link} to="/edit-profile">
                                Edit Profile
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to="/profile"
                                onClick={() => {
                                  user.logoutHanlder();
                                }}
                              >
                                Logout
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  lg={9}
                  md={12}
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                  {getMenuButtons()}
                  {user.isLogin && (
                    <Button
                      aria-label="delete"
                      size="small"
                      component={Link}
                      to="/notification"
                    >
                      <IoIosNotifications
                        fontSize="30"
                        style={{ color: "rgba(96, 104, 125, 1)" }}
                      />
                      {user?.unreadCount !== 0 ? <span className="dots" /> : ""}
                      {/* <span className="dots" /> */}
                    </Button>
                  )}
                  <input
                    type="text"
                    id="headerWalletAddress"
                    value={user.userData?.wallet_address}
                    style={{ display: "none" }}
                  />
                  <Button
                    className={wallet}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(privateBlockchainUrl, "_blank");
                    }}
                  >
                    Switch to Private
                  </Button>
                  {user.isLogin && account ? (
                    <CopyToClipboard text={user.userData?.wallet_address}>
                      <Button
                        className={wallet}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={() => toast.info("Copied")}
                      >
                        {sortAddress(account)}{" "}
                      </Button>
                    </CopyToClipboard>
                  ) : (
                    <Button
                      className={wallet}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      to="/wallet"
                      component={Link}
                    >
                      Connect wallet
                    </Button>
                  )}
                  {user.isLogin && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      className={login}
                      ref={anchorRef.user}
                      aria-controls={open1.user ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={() => handleToggle("user")}
                    >
                      {" "}
                      <Avatar
                        className={loginButton}
                        src={_profile?.profile_picture}
                      />
                    </IconButton>
                  )}
                  <Popper
                    open={open1.user}
                    anchorEl={anchorRef.user.current}
                    role={undefined}
                    transition
                    disablePortal
                    className={submenu}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={(e) => handleClose2(e, "user")}
                          >
                            <MenuList
                              autoFocusItem={open1.user}
                              id="menu-list-grow"
                              onKeyDown={(e) => handleListKeyDown(e, "user")}
                            >
                              <MenuItem component={Link} to="/profile">
                                My Profile
                              </MenuItem>
                              <MenuItem component={Link} to="/edit-profile">
                                Edit Profile
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to="/"
                                onClick={(e) => {
                                  user.logoutHanlder();
                                  handleClose2(e, "user");
                                }}
                              >
                                Logout
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Box>
          <div className={search}>
            <form onSubmit={handleSearch}>
              <Button className={searchIcon}>
                <SearchIcon />
              </Button>
              <InputBase
                placeholder="Search By Name, Category, Organization, NFTID and Attributes"
                classes={{
                  root: inputRoot,
                  input: inputInputMobile,
                }}
                type="search"
                inputProps={{ "aria-label": "search" }}
                value={searchKey}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                }}
              />
            </form>
          </div>
        </Box>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {!user.isLogin && <>{productLogo}</>}
            {user.isLogin && (
              <>
                {productLogo}
                {/* <img className={logoDrawer} src="images/ReactLogo.svg" alt="" /> */}
                <Box mt={2} style={{ display: "flex" }} spacing={2}>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    className={login}
                    ref={anchorRef.user}
                    aria-controls={open1.user ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => handleToggle("user")}
                  >
                    {" "}
                    <Avatar
                      className={loginButton}
                      src={_profile?.profile_picture}
                    />
                  </IconButton>
                  <input
                    type="text"
                    id="headerWalletAddress"
                    value={user.userData?.wallet_address}
                    style={{ display: "none" }}
                  />

                  <CopyToClipboard text={user.userData?.wallet_address}>
                    <Button
                      className={wallet}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={() => toast.info("Copied")}
                    >
                      {sortAddress(account)}{" "}
                    </Button>
                  </CopyToClipboard>
                </Box>
              </>
            )}
            {open1.user && (
              <Popper
                open={open1.user}
                anchorEl={anchorRef.user.current}
                role={undefined}
                transition
                disablePortal
                className={submenu}
                style={{ zIndex: "2" }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(e) => handleClose2(e, "user")}
                      >
                        <MenuList
                          autoFocusItem={open1.user}
                          id="menu-list-grow"
                          onKeyDown={(e) => handleListKeyDown(e, "user")}
                        >
                          <MenuItem component={Link} to="/profile">
                            My Profile
                          </MenuItem>
                          <MenuItem component={Link} to="/edit-profile">
                            Edit Profile
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/"
                            onClick={() => {
                              user.logoutHanlder();
                            }}
                          >
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )}
            {getDrawerChoices()}
            <Button
              className={wallet}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => {
                e.preventDefault();
                window.open(privateBlockchainUrl, "_blank");
              }}
            >
              Switch to Private
            </Button>
            {!user.isLogin && (
              <Button
                className={wallet}
                aria-controls="simple-menu"
                aria-haspopup="true"
                to="/wallet"
                component={Link}
              >
                Connect wallet
              </Button>
            )}
          </div>
        </Drawer>

        {/* <div>{productLogo}</div> */}
        <Grid container>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  useEffect(() => {
    if (user.isLogin && user.userData.id) {
      // _notification_Listener_Function(user.userData.id);
    }
  }, [user]);
  const getDrawerChoices = () => {
    return (
      <>
        {!user.isLogin ? (
          <>
            <NavLink
              className={NavBox}
              exact
              activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
              to="/"
              style={{ textDecoration: "none" }}
            >
              Home
            </NavLink>
            &nbsp;&nbsp;&nbsp;
            <NavLink
              activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
              style={{ textDecoration: "none" }}
              to="/marketplace"
              className={NavBox}
            >
              Explore
            </NavLink>
          </>
        ) : (
          headersData.map(({ label, href }) => {
            return (
              <>
                <Button
                  {...{
                    key: label,
                    color: "inherit",
                    to: href,
                    component: Link,
                    className: menuButton1,
                  }}
                >
                  <MenuItem className={menuMobile}>{label}</MenuItem>
                </Button>
              </>
            );
          })
        )}
      </>
    );
  };

  const productLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return (
      <>
        {!user.isLogin ? (
          <>
            <NavLink
              className={NavBox}
              exact
              activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
              to="/"
              // style={{ "&:hover": "red" }}
            >
              Home
            </NavLink>
            &nbsp;&nbsp;&nbsp;
            <NavLink
              activeStyle={{ color: "rgba(83, 68, 255, 0.9)" }}
              style={{ textDecoration: "none" }}
              to="/marketplace"
              className={NavBox}
            >
              Explore
            </NavLink>
          </>
        ) : (
          headersData.map(({ label, href }) => {
            return (
              <>
                <NavLink key={label} exact to={href} className={menuButton}>
                  {label}
                </NavLink>
              </>
            );
          })
        )}
      </>
    );
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        // className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", borderBottom: "1px solid #3c3c3c" }}
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notificaitons</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>
    </>
  );
}
