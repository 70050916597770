const baseurl = "https://node-digitalstore.mobiloitte.com";
let user = `${baseurl}/api/v1/user`;
let static1 = `${baseurl}/api/v1/static`;
let nft = `${baseurl}/api/v1/nft`;
let order = `${baseurl}/api/v1/order`;
let admin = `${baseurl}/api/v1/admin`;
let notification = `${baseurl}/api/v1/notification`;
let staticContent = `${baseurl}/api/v1/static`;
let chat = `${baseurl}/api/v1/chat`;

export const socketURL = "wss://node-digitalstore.mobiloitte.com/";
// export const socketURL = "wss://node.gravitypull.io/";
// export const socketURL = "ws://172.16.2.7:3033";
// export const socketURL = "ws://172.16.1.246:3032";

export const websiteName =
  window.location.protocol + "//" + window.location.host;
// export const privateBlockchainUrl = "https://admin.gravitypull.io/";
export const privateBlockchainUrl = "https://huba-admin.mobiloitte.org/";
// export const privateBlockchainUrl = "http://172.16.6.42:3006";

const apiConfig = {
  addSuggestion: `${nft}/addSuggestion`,
  addProposalOnNft: `${nft}/addProposalOnNft`,
  viewNft: `${nft}/viewNft`,
  connectWallet: `${user}/connectWallet`,
  profile: `${user}/getProfile`,
  editProfile: `${user}/editProfile`,
  userlistalluser: `${user}/listAllUser`,
  listBannerOnHome: `${user}/listBannerOnHome`,
  uploadNFT: `${nft}/uploadNFT`,
  updateNFT: `${nft}/UpdateAsset`,
  sentoMarketNFT: `${nft}/nftSendToMarketPlace`,
  listNFT: `${nft}/listMyNft`,
  publishedList: `${nft}/publishedList`,
  listMyLicence: `${nft}/listMyLicence`,
  listConversations: `${chat}/listConversations`,
  chatByIdApi: `${chat}/chatByIdApi`,
  topSuggestedDesigns: `${nft}/topSuggestionList`,
  allSuggestionOnNft: `${nft}/allSuggestionOnNft`,
  // newDesign: `${nft}/listNFTOnMaketplace`,
  explorerList: `${nft}/explorerList`,
  newDesign: `${nft}/explorerList`,
  apilistNftAccordingToCategory: `${nft}/listNftAccordingToCategory`,
  listCategory: `${admin}/listCategory`,
  activity: `${user}/myActivity`,
  addCotegory: `${admin}/addCategory`,
  proposalList: `${nft}/proposalList`,
  acceptRejectProposal: `${nft}/acceptRejectProposal`,
  addTermsAndCondition: `${nft}/addTermsAndCondition`,
  viewTermsAndCondition: `${nft}/viewTermsAndCondition`,
  ipfsUpload: `${nft}/ipfsUpload`,
  uploadFile: `${admin}/uploadFile`,
  viewVersionNft: `${nft}/viewVersionNft`,
  viewCategory: `${admin}/viewCategory`,
  listNotification: `${user}/listNotification`,
  staticContentList: `${staticContent}/staticContentList`,
  createOrder: `${nft}/createOrder`,
  myOrder: `${nft}/listMyOrder`,
  buyOrder: `${nft}/buyOrder`,
  licenceNftHolder: `${nft}/licenceNftHolder`,
  addJenQr: `${nft}/addJenQr`,
  showNftHistory: `${nft}/showNftHistory`,
  showEventHistory: `${nft}/showEventHistory`,
  getPermission: `${admin}/getPermission`,
  unPublishedList: `${nft}/unPublishedList`,
  publishedList: `${nft}/publishedList`,
  faqList: `${static1}/faqList`,
};

export const socketConfig = {
  getOnline: `onlineUser`,
  StartChat: `oneToOneChat`,
  chatHistory: `chatHistory`,
  chatById: `chatById`,
  disconnect: `disconnect`,
  _notificationList: `getNotificationList`,
};
export default apiConfig;
