import React, { useContext } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { UserContext } from "src/context/User";

export default function AuthGuard(props) {
  const history = useHistory();
  const location = useLocation();
  const { children } = props;
  const auth = useContext(UserContext);
  if (!auth.isLogin) {
    if (location.search) {
      history.push({
        pathname: "/wallet",
        state: { from: location?.pathname + location?.search },
      });
    } else {
      return <Redirect to="/wallet" />;
    }
  }

  return <>{children}</>;
}
